import React, {useEffect, useRef, useState} from 'react';
import { scaleBand, scaleLinear, max, axisLeft, select, axisBottom} from 'd3';
import * as d3 from 'd3';
import styles from './StackedBarChart.module.css';
import { useDispatch, useSelector } from "react-redux";
import {
    clearSelectedProduct, clearTrimCode,
    removeProduct,
    setSelectedProduct, setTrimCode, fetchStackedBarData, addStackedName
} from "../../../service/reducers/StackedBarChart/ProductCodeChartSlice";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import styleTooltip from "../TestMapD/GeoChart.module.css"
import Legend from "../../../components/DiagrammLegend/Legend";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {formatNumberWithDecimal} from "../../../utils/countFunc";
import {formatCurrency} from "../../../utils/rubbleFunc";
import {cancelAllPendingRequests} from "../../../api/api";
import {findOkpdNameByCode} from "../../../utils/findOKPDNameGlobal";
import {truncateText} from "../../../utils/trunctateText";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import tooltipNames from "../../../utils/tooltipTitles.json";
import localStorageService from "../../../service/localStorage/localStorageService";
const StackedBarChart = ({ onZoomClick, zoomedDiagram }) => {
    const svgRef = useRef();
    const dispatch = useDispatch();
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const tooltipRef = useRef(null);
    const [legendData, setLegendData] = useState([]);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const selectedProductSegments = useSelector((state) => state.productCode.selectedProduct);
    const { ref, width, height } = useResizeObserver();
    const { stackedBarData, loading } = useSelector((state) => state.productCode);
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const isInsideActive = useSelector((state) => state.productCode.isInsideActive);
    const insideActiveRef = useRef(isInsideActive);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth)
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    insideActiveRef.current = isInsideActive;
    const top = useSelector((state) => state.activitySlice);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const selectedTreeMapLabels = selectedSegments.map(segment => segment.label);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const okpdData = localStorageService.getItem('okpdData') || [];

    useEffect(() => {
        const requestData = {
            relatedINNs,
            pieState,
            activeRegions,
            trimCode,
            topBody,
            selectedTreeMapLabels,
            selectedMonth,
            filterOkpd,
            selectedDonutSegmetsV1,
            activeTab,
            selectedKbrSegments,
            selectedZoomableSegment,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };

        dispatch(fetchStackedBarData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedZoomableSegment,selectedKbrSegments,filterOkpd, dateChanger, pieState, activeRegions, top, selectedSegments, selectedMonth, relatedINNs, trimCode, selectedDonutSegmetsV1, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);


    useEffect(() => {
        if (loading === 'successful' && ((width && height))) {
            const uniqueLegendData = extractLegendData(stackedBarData);
            setLegendData(uniqueLegendData);
            drawChart(stackedBarData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, stackedBarData, selectedProduct, isLoadingMenu]);


    const headerWithThreeButtons = {
        title: "Объемы продукции по коду ОКПД2",
        icons: [
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, activeIcon: null, width: 20, height: 20, onClick: onZoomClick },
            { name: 'inside', icon: icons.inside, activeIcon: icons.insideActive, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, activeIcon: null, width: 20, height: 20 }
        ]
    };

    const handleClickSeg = (productId) => {
        cancelAllPendingRequests()
        const isProductAlreadySelected = !!selectedProductSegments.find(p => p.productId === productId);
        if (isProductAlreadySelected) {
            dispatch(removeProduct({ productId }));
            dispatch(addStackedName(headerWithThreeButtons.title));
        } else {
            dispatch(setSelectedProduct({ productId }));
            dispatch(addStackedName(headerWithThreeButtons.title));
        }
    };

    const handleClickIn = (productId) => {
        cancelAllPendingRequests()
        const parts = productId.split('.').filter(p => p !== "");
        const currentTrimParts = (trimCode && trimCode.length > 0) ? trimCode[0].split('.').filter(p => p !== "") : [];

        let newTrimCode = "";

        if (currentTrimParts.length < parts.length) {
            newTrimCode = parts.slice(0, currentTrimParts.length + 1).join('.') + '.';
        } else {
            newTrimCode = parts[0] + '.';
        }
        dispatch(clearSelectedProduct());
        dispatch(addStackedName(headerWithThreeButtons.title));
        dispatch(clearTrimCode());
        dispatch(setTrimCode([newTrimCode]));
    };
    const extractLegendData = (stackedBarData) => {
        const totalsMap = new Map();
        stackedBarData.forEach(dataItem => {
            dataItem.labels.forEach((labelObj, index) => {
                const currentValue = totalsMap.get(labelObj.label) || { total: 0, color: dataItem.extra.find(ex => ex.label === 'colors').value[index] };
                currentValue.total += dataItem.values[index];
                totalsMap.set(labelObj.label, currentValue);
            });
        });
        const sortedLegendData = Array.from(totalsMap)
            .sort((a, b) => b[1].total - a[1].total)
            .map(([label, data]) => ({ label, color: data.color }));

        return sortedLegendData;
    };


    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });
    const onMouseMove = (event, label, value, okpd) => {
        const tooltipConfig = tooltipNames.StackedBarChartNotification.Tabs[activeTab];
        const okpdName = findOkpdNameByCode(okpd, okpdData);
        const tooltipData = [
            { label: tooltipConfig.oKPDCode, value: okpd },
            { label: tooltipConfig.oKPDName, value: okpdName || 'Неизвестно' },
            { label: tooltipConfig.label, value: label },
            { label: tooltipConfig.value, value: formatCurrency(value) }
        ];
        const tooltipSize = calculateTooltipSize([okpd]);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: tooltipData
        });
    };

    const onMouseMoveLables = (event, label) => {
        const okpdName = findOkpdNameByCode(label, okpdData);
        const tooltipData = [
            { value: `${okpdName}(${label})`},
        ];

        const tooltipSize = calculateTooltipSize([label]);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: tooltipData
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const drawChart = (data) => {
        const MIN_SEGMENT_WIDTH = 4.6;
        const segmentSums = {};
        data.forEach(barData => {
            barData.labels.forEach((label, index) => {
                if (!segmentSums[label]) {
                    segmentSums[label] = 0;
                }
                segmentSums[label] += barData.values[index];
            });
        });
        const barHeight = 20;
        const margin = { top: 10, right: 30, bottom: 0, left: 55 };
        const container = document.getElementById("container");
        if (!container) return;
        const containerWidth = parseFloat(window.getComputedStyle(container).width);
        const width = containerWidth - margin.left - margin.right;
        const height = barHeight * data.length;
        const minWidthPerTick = 50;
        const numberOfTicks = Math.floor(width / minWidthPerTick / 2);
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();
        svg.attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom);
        const g = svg.append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);
        const x = scaleLinear()
            .domain([0, max(data, (d) => d.total)])
            .range([0, width]);
        const y = scaleBand().domain(data.map((d) => d.label))
            .range([0, height])
            .padding(0.1);

        g.append("g")
            .call(axisLeft(y).tickFormat(d => truncateText(d, 10)))
            .select(".domain").attr("stroke", "#EDF1F5");

        const selectedProductIds = new Set(selectedProduct.map(p => p.productId));
        const isAnyProductSelected = selectedProductSegments.length > 0;

        g.selectAll(".tick text")
            .attr("fill", (d) => selectedProductIds.has(d) ? "black" : "#8D96B2")
            .attr("font-family", "Golos Regular")
            .attr("cursor", "pointer")
            .on('mouseover', (event, d) => onMouseMoveLables(event, d))
            .on('mouseout', onMouseOut)
            .on('click', function(event, d) {
                event.stopPropagation();
                onMouseOut();
                if (insideActiveRef.current) {
                    handleClickIn(d);
                } else {
                    handleClickSeg(d);
                }
                select(this).attr("fill", selectedProductIds.has(d) ? "#8D96B2" : "black");
            })

        const xAxisSvg = select("#xAxisSvg");
        xAxisSvg.selectAll("*").remove();
        xAxisSvg.attr("width", width + margin.left + margin.right)
            .attr("height", 30);
        xAxisSvg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(axisBottom(x).ticks(numberOfTicks).tickFormat((d) => formatNumberWithDecimal(d)))
            .selectAll("g.tick")
            .attr("font-family", "Golos Regular")
            .each(function() {
                select(this).select("text")
                    .attr("fill", "#8D96B2")
                    .attr("font-family", "Golos Regular");
            });
        xAxisSvg.select(".domain").attr("stroke", "#EDF1F5");
        xAxisSvg.selectAll(".tick line").remove();

        //убрал все тики с свг?
        g.selectAll(".tick line").remove();

        data.forEach((barData) => {
            let xOffset = 0;
            let totalValue = 0;
            let lastSegmentIndex = barData.values.length - 1;
            let lastSegmentValue = barData.values[lastSegmentIndex];

            g.append("rect")
                .attr("x", 0)
                .attr("y", y(barData.label))
                .attr("width", x(barData.total))
                .attr("height", y.bandwidth())
                .attr("fill", "transparent");

            barData.labels.forEach((labelObj, segmentIndex) => {
                const label = labelObj.label;
                const segmentValue = barData.values[segmentIndex];
                const segmentColor = barData.extra[0].value[segmentIndex];
                const isLastSegment = segmentIndex === barData.labels.length - 1;
                const isActive = !isAnyProductSelected || selectedProductSegments.find(p => p.productId === barData.label);
                const hasSelectedSegments = selectedProductSegments.length > 0;
                totalValue += segmentValue;

                if (!isLastSegment) {
                    const rect = g.append("rect")
                        .attr("x", xOffset)
                        .attr("y", y(barData.label))
                        .attr("width", 0)
                        .attr("height", y.bandwidth())
                        .attr("fill", segmentColor)
                        .attr("opacity", () => {
                            const isActive = !isAnyProductSelected || selectedProductSegments.find(p => p.productId === barData.label);
                            return isActive ? 1 : 0.5;
                        })
                        .on('click', (event) => {
                            event.stopPropagation();
                            onMouseOut();
                            if (insideActiveRef.current) {
                                handleClickIn(barData.label);
                            } else {
                                handleClickSeg(barData.label);
                            }
                        });

                    rect.on('mousemove', (event) => onMouseMove(event, label, segmentValue, barData.label))
                        .on('mouseout', onMouseOut);

                    rect.transition()
                        .duration(800)
                        .attr("width", x(segmentValue))
                        .ease(d3.easeCubicInOut);
                } else {
                    const radius = 5;
                    const segmentWidth = Math.max(x(segmentValue), MIN_SEGMENT_WIDTH);
                    const adjustedSegmentWidth = Math.max(segmentWidth - radius, 0);
                    const initialPath = `M${xOffset},${y(barData.label)} 
    h0
    q0,0 0,${radius} 
    v${y.bandwidth() - radius * 2} 
    q0,0 0,${radius} 
    h-0
    z`;
                    const finalPath = `M${xOffset},${y(barData.label)} 
h${adjustedSegmentWidth} 
q${radius},0 ${radius},${radius} 
v${y.bandwidth() - radius * 2} 
q0,${radius} -${radius},${radius} 
h-${adjustedSegmentWidth} 
z`;

                    const path = g.append("path")
                        .attr("d", initialPath)
                        .attr("opacity", () => isActive ? 1 : 0.5)
                        .attr("x", xOffset)
                        .attr("y", y(barData.label))
                        .attr("width", 0)
                        .attr("height", y.bandwidth())
                        .attr("fill", segmentColor)
                        .attr("opacity", () => {
                            const isActive = !isAnyProductSelected || selectedProductSegments.find(p => p.productId === barData.label);
                            return isActive ? 1 : 0.5;
                        })
                    path.on('click', (event) => {
                            event.stopPropagation();
                            onMouseOut();
                            if (insideActiveRef.current) {
                                handleClickIn(barData.label);
                            } else {
                                handleClickSeg(barData.label);
                            }
                        })
                    path.on('mousemove', (event) => onMouseMove(event, label, segmentValue, barData.label))
                        .on('mouseout', onMouseOut)

                    path.transition()
                        .duration(800)
                        .attr("d", finalPath)
                        .ease(d3.easeCubicInOut);
                }


                if (segmentValue === lastSegmentValue) {
                    if (isActive && hasSelectedSegments) {
                        const textElement = g.append("text")
                            .attr("x", xOffset + x(segmentValue) + 5)
                            .attr("y", y(barData.label) + y.bandwidth() / 2)
                            .attr("dy", ".35em")
                            .attr("font-family", "Golos Regular")
                            .attr("font-size", "12px")
                            .attr("fill", "var(--text-color, #FFF)")
                            .attr("opacity", 0)
                            .text(formatNumberWithDecimal(totalValue));

                        textElement.on('mousemove', (event) => onMouseMove(event, label, segmentValue, barData.label))
                            .on('mouseout', onMouseOut);

                        const textWidth = textElement.node().getComputedTextLength();
                        const remainingSpaceRight = width - (xOffset + x(segmentValue) + 5);

                        if (textWidth > remainingSpaceRight) {
                            textElement.attr("x", xOffset + x(segmentValue) - textWidth - 5)
                                .attr("fill", "#EDF1F5");
                        }

                        textElement.transition()
                            .delay(550)
                            .attr("opacity", 1);
                    }
                }
                xOffset += x(segmentValue);
            });
        });
    };


    return (
        <div ref={ref} className={`${style.container} ${selectedProduct.length > 0 || trimCode?.length > 0 ? style.selected : ''}  ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    diagramId={"Stacked"}
                />
            </div>
            {isLoadingMenu || loading === 'pending' || loading === 'failed' ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={"Структура закупок по ОКПД 2"} data={legendData} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={legendData.map(d => d.color)} />
                    </div>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styleTooltip.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}</strong>{item.value}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className={`${styles.containerContent} ${zoomedDiagram ? styles.zoomed : ''}`} id="container">
                        <svg ref={svgRef}></svg>
                    </div>
                    <div id="xAxisContainer">
                        <svg id="xAxisSvg"></svg>
                    </div>
                </>
            )}
        </div>
    );
};

export default StackedBarChart;
