import React, { useEffect } from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { store } from './service/redux/store';
import Layout from './components/Layout/Layout';
import './vendor/fonts/global.css';
import { ModalProvider } from './hook/useModal';
import DContainer from './pages/DashPage/DiagramContainer/DContainer';
import {fetchColors, getOkpd2} from './api/api';
import localStorageService from "./service/localStorage/localStorageService";
import YandexMetrika from "./components/YaMetrica/YaMetrica";
function App() {
    useEffect(() => {
        const loadColors = async () => {
            try {
                await fetchColors();
            } catch (error) {
                console.error("Не удалось загрузить цвета: ", error);
            }
        };
        loadColors();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiData = await getOkpd2();
                const localStorageData = localStorageService.getItem('okpdData');
                if (!localStorageData || JSON.stringify(apiData) !== JSON.stringify(localStorageData)) {
                    localStorageService.setItem('okpdData', apiData.result);
                }
            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <ModalProvider>
                    <YandexMetrika/>
                        <Routes>
                            <Route path="/" element={<Layout/>}>
                                <Route index element={<DContainer />} />
                                <Route path="dashboard" element={<DContainer />} />
                               {/* <Route path="search" element={<TestSearch />} />
                                <Route path="ratings" element={<TestRatings />} />*/}
                            </Route>
                          {/*  <Route path="/personal" element={<Layout/>}>
                                <Route index element={<PersonalSettings />} />
                            </Route>
                            <Route path="/test" element={<TestP/>}>
                            </Route>
                            <Route path="/payment-success" element={<SuccessPage />} />*/}
                            <Route path="*" element={<div>Not Found</div>} />
                        </Routes>
                </ModalProvider>
            </Router>
        </Provider>
    );
}

export default App;
