import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import {activeColors} from "../../../utils/colors";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import Legend from "../../../components/DiagrammLegend/Legend";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {fetchDonutKbrData, setShowCountKbr} from "../../../service/reducers/KbrChart/DonutChartKbrSlice";
import styleTooltip from "../TestMapD/GeoChart.module.css";
import {formatCurrency} from "../../../utils/rubbleFunc";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import styles from "../TestMapD/GeoChart.module.css";
import tooltipNames from "../../../utils/tooltipTitles.json"
import {cancelAllPendingRequests} from "../../../api/api";
import {setActiveLegendItem} from "../../../service/reducers/legendItemsClick";
import {addKbrChartName, toggleSegment} from "../../../service/reducers/KbrChart/DonutChartKbrSegmentSlice";

const TopDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const { width, height } = useResizeObserver({ ref });
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { KbrData, loading, showCountKbr } = useSelector((state) => state.kbrData);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);

    const top = useSelector((state) => state.activitySlice);
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const selectedTreeMapLabels = useMemo(() => {
        return selectedSegments.map(segment => segment.label);
    }, [selectedSegments]);

    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const getHeaderTitle = (activeTab, showCountKbr) => {
        switch (activeTab) {
            case 'Извещения':
                return `Структура закупок по кодам видов расходов ${showCountKbr === 'count' ? "(от кол-ва)" : "(от суммы)"}`;
            case 'Контракты':
                return `Статусы исполнения контрактов ${showCountKbr === 'count' ? "(от кол-ва)" : "(от суммы)"}`;
            case 'Исполнение':
                return `Структура контрактов по способу определения поставшика ${showCountKbr === 'count' ? "(от кол-ва)" : "(от суммы)"}`;
            default:
                return 'Unknown Tab';
        }
    }
    const headerTitle = getHeaderTitle(activeTab, showCountKbr);
    const headerWithThreeButtons = {
        title: headerTitle,
        icons: [
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'change', icon: icons.change, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };

    useEffect(() => {
        const requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            selectedCountryLine,
            trimCode,
            filterOkpd,
            selectedTreeMapLabels,
            selectedMonth,
            activeTab,
            selectedOkpd,
            contractTrimCode,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            selectedZoomableSegment,
            regNumArray,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };
        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedTreeMapLabels = selectedTreeMapLabels;
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedCustSegments = selectedTreeMapLabels;
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        }
        dispatch(fetchDonutKbrData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureRegNum,selectedZoomableSegment,filterOkpd,dateChanger,selectedCountryLine,selectedProduct, activeRegions, pieState, top, trimCode, selectedSegments, selectedMonth, relatedINNs, selectedOkpd, contractTrimCode, selectedDonutSegmetsV1, selectedContractMonth, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);


    useEffect(() => {
        if (loading === 'successful' && width && height && KbrData && KbrData.nodes) {
            createDonutChart(KbrData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, KbrData, showCountKbr,selectedKbrSegments]);

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const mode = showCountKbr === 'count' ? 'count' : 'sum';
        const commonConfig = tooltipNames['DonutChartV1']['Tabs'][activeTab]['common'];
        const specificConfig = tooltipNames['DonutChartV1']['Tabs'][activeTab][mode];
        let tooltipText = [];

        Object.entries(commonConfig).forEach(([key, russianKey]) => {
            if (key === "KVR Code" && russianKey) {
                tooltipText.push({ label: russianKey, value: d.data.label });
            } else if (key === "Name") {
                if(activeTab === 'Извещения') {
                    const nameInfo = d.data.extra.find(x => x.label === 'purchase_code');
                    tooltipText.push({ label: russianKey, value: nameInfo ? nameInfo.value : 'Н/Д' });
                }
                else {
                    tooltipText.push({ label: russianKey, value: d.data.label });
                }
            }
        });
        Object.entries(specificConfig).forEach(([key, russianKey]) => {
            if (key === "Volume") {
                tooltipText.push({ label: russianKey, value: formatCurrency(d.data.value) });
            } else if (key === "Quantity") {
                const countInfo = d.data.extra.find(x => x.label === 'count');
                tooltipText.push({ label: russianKey, value: countInfo ? `${countInfo.value} шт` : 'Н/Д' });
            }
        });
        let tooltipSize = calculateTooltipSize(tooltipText.map(item => `<div><strong>${item.label}</strong>: ${item.value}</div>`));
        let x = event.pageX + 10;
        let y = event.pageY + 10;
        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({ x, y, text: tooltipText });
    };
    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const onSegmentClick = (event, d) => {
        cancelAllPendingRequests();
        onMouseOut();
        const identifier = activeTab === 'Извещения' ?
            d.data.extra.find(item => item.label === 'purchase_code').value :
            d.data.label;
        dispatch(toggleSegment(identifier));
        dispatch(addKbrChartName(headerTitle));
        dispatch(setActiveLegendItem({ diagramId: headerTitle, activeItem: identifier }));
    };
    const onLegendItemClick = (label) => {
        cancelAllPendingRequests()
        onMouseOut();
        dispatch(toggleSegment(label));
        dispatch(addKbrChartName(headerTitle));
    };

    const createDonutChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height);
        let sortedData;
        let totalValue;

        if (showCountKbr === 'count') {
            totalValue = data.nodes.reduce((acc, cur) => acc + ((cur.extra && cur.extra[0].value) || 0), 0);
            sortedData = [...data.nodes].map(d => ({
                ...d,
                value: (d.extra && d.extra[0].value) || 0
            })).sort((a, b) => b.value - a.value);
        } else {
            totalValue = data.total;
            sortedData = [...data.nodes].sort((a, b) => b.value - a.value);
        }
        const radius = Math.min(width, height) * 0.35;
        const color = d3.scaleOrdinal().domain(sortedData.map(d => d.label)).range(activeColors);

        const pie = d3.pie().value(d => d.value).padAngle(0.01);
        const arcData = pie(sortedData);
        const padAngle = 0.01;

        const fontSizeScale = d3.scaleLinear()
            .domain([100, 600])
            .range([10, 24]);

        const fontSize = fontSizeScale(radius);

        const arcGenerator = d3.arc()
            .innerRadius(radius * 0.7)
            .outerRadius(radius);

        const arcs = svg.append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`)
            .selectAll("path")
            .data(arcData)
            .enter()
            .append("path")
            .attr("class", d => {
                const percentage = ((d.data.value / totalValue) * 100).toFixed(1);
                return percentage < 3 ? "small-segment" : "large-segment";
            })
            .attr("fill", d => color(d.data.label))
            .attr("stroke", d => {
                const identifier = activeTab === 'Извещения' ?
                    d.data.extra.find(item => item.label === 'purchase_code')?.value :
                    d.data.label;
                return selectedKbrSegments.includes(identifier) ? "black" : "none";
            })
            .attr("stroke-width", d => {
                const identifier = activeTab === 'Извещения' ?
                    d.data.extra.find(item => item.label === 'purchase_code')?.value :
                    d.data.label;
                return selectedKbrSegments.includes(identifier) ? 0.5 : 0;
            })
            .attr("opacity", d => {
                const identifier = activeTab === 'Извещения' ?
                    d.data.extra.find(item => item.label === 'purchase_code')?.value :
                    d.data.label;
                return selectedKbrSegments.length === 0 || selectedKbrSegments.includes(identifier) ? 1 : 0.3;
            })
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', onMouseOut)
            .on('click', (event, d) => onSegmentClick(event, d));

        arcs.transition()
            .duration(1000)
            .attrTween("d", arcTween(arcGenerator, padAngle));

        function arcTween(arcGenerator, padAngle) {
            return function(d) {
                const minSize = padAngle * 2;
                const i = d3.interpolate(d.startAngle + padAngle, Math.max(d.endAngle - padAngle, d.startAngle + minSize));
                return function(t) {
                    d.endAngle = i(t);
                    return arcGenerator(d);
                };
            };
        }

        let overlappingTexts = [];
        svg.append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`)
            .selectAll("text")
            .data(arcData)
            .enter()
            .append("text")
            .attr("font-size", `${fontSize}px`)
            .attr("font-family", "Golos Regular")
            .attr("transform", function(d) {
                const [x, y] = arcGenerator.centroid(d);
                return `translate(${x}, ${y})`;
            })
            .attr("text-anchor", "middle")
            .attr("fill", "white")
            .text(function(d) {
                const percentage = ((d.data.value / totalValue) * 100).toFixed(1);
                return percentage >= 4 ? `${percentage}%` : "";
            })
            .each(function(d) {
            const thisText = d3.select(this);
            thisText.text(`${((d.data.value / totalValue) * 100).toFixed(1)}%`);
            const bbox = thisText.node().getBBox();
            const textWidth = bbox.width;
            const angleDifference = d.endAngle - d.startAngle;
            const segmentWidth = angleDifference * radius;
                if (textWidth > segmentWidth) {
                    const [x, y] = arcGenerator.centroid(d);
                    thisText
                        .attr("transform", `translate(${x}, ${y - 20})`)
                        .attr("fill", "var(--text-color, #FFF)");

                    overlappingTexts.push({
                        textElement: thisText,
                        value: d.data.value
                    });
                }
             });
                if (overlappingTexts.length > 1) {
                    const maxText = d3.max(overlappingTexts, d => d.value);
                    overlappingTexts.forEach(d => {
                        if (d.value !== maxText) {
                            d.textElement.attr("visibility", "hidden");
                        }
                    });
                }
    };

    const legendData = KbrData && KbrData.nodes ? (
        (activeTab === 'Извещения' ?
                KbrData.nodes.map(node => ({
                    label: node.extra.find(x => x.label === 'purchase_code').value || 'Нет данных',
                    value: node.value,
                    extraLabel: node.label
                })) :
                KbrData.nodes.map(node => ({
                    label: node.label,
                    value: node.value || "Нет данных"
                }))
        ).sort((a, b) => b.value - a.value)
    ) : [];

    return (
        <div className={`${style.container} ${selectedKbrSegments.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    activeMode={showCountKbr}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'sum') {
                            dispatch(setShowCountKbr('sum'));
                        } else if (mode === 'count') {
                            dispatch(setShowCountKbr('count'));
                        }
                    }}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={headerTitle} data={legendData} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={activeColors} onLegendItemClick={onLegendItemClick} />
                    </div>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styleTooltip.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}: </strong>{item.value}
                                </div>
                            ))}
                        </div>
                    )}
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                </>
            )}
        </div>
    );
};

export default TopDiagramm;
