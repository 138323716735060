import styles from "../Layout.module.css";
import SelectedSegments from "../../SelectedSegments/SelectedSegments";
import {Outlet} from "react-router-dom";
import CommonHeader from "../../Header/ComonHeader";
import React from "react";
import HeadDashContent from "../../../pages/DashPage/HeadContent/HeadContent";
const DashBoard = () => {
   /* useEffect(() => {
        axios.get("https://backend-public.tender.agatha-hub.ru/personal-area/me_group", {
            withCredentials: true
        })
            .then(response => {
                dispatch(setGroupsRed(response.data));
            })
            .catch(error => {
                console.error("Ошибка при загрузке данных групп", error);
            });

        return () => {
            dispatch(resetSelectedOrganization());
            dispatch(resetRelatedINNs());
        };
        // eslint-disable-next-line
    }, []);*/

    /*const handleChange = (event) => {
        const selectedValue = event.target.value;
        let selectedItem = groups.find(group => group.id === selectedValue) || okpdListData.find(item => item.okpd_code === selectedValue);
        let valueToStore = '';

        if (selectedItem) {
            dispatch(setSelectedComboItem(selectedItem))
            if (selectedItem.label) {
                valueToStore = selectedItem.label;
            } else if (selectedItem.okpd_code && selectedItem.okpd_name) {
                valueToStore = `${selectedItem.okpd_code} ${selectedItem.okpd_name}`;
            }
            dispatch(setSelectedValue(valueToStore));
        } else {
            console.log("Элемент не найден");
            return;
        }
        const isOkpd = okpdListData.some(item => item.okpd_code === selectedValue);
        if (isOkpd) {
            dispatch(setOkpdCode(selectedValue))
            dispatch(resetSelectedOrganization())
            dispatch(resetRelatedINNs())
            dispatch(resetSearchOrgINNINNs());
            dispatch(resetSearchSuppINNINNINNs());
        } else {
            const selectedOrg = groups.find(group => group.id === selectedValue);
            dispatch(setSelectedOrganization(selectedOrg));
            dispatch(setRelatedINNs(selectedValue));
            dispatch(resetSearchOrgINNINNs());
            dispatch(resetSearchSuppINNINNINNs());
            dispatch(resetOkpdCode());
        }
        dispatch(resetSelectedSuggestion())
        clearAll();
    };*/

    return(
        <div className={styles.mainContainer}>
            <CommonHeader title="Дашборд">
                {/*<Select
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    value={displayValue}
                    displayEmpty
                    renderValue={selected => {
                        if (!selected) {
                            return <span style={{ color: '#8D96B2' }}>Выберите группу или ОКПД2</span>;
                        }
                        return selected;
                    }}
                    onChange={handleChange}
                    onFocus={() => setIsCMBoxFocused(true)}
                    onBlur={() => setIsCMBoxFocused(false)}
                    sx={{
                        height: '42px',
                        backgroundColor: 'var(--headerCmg-bg-color, #FFF)',
                        transition: 'background-color 0.5s ease-in-out, color 0.5s ease-in-out',
                        borderRadius: '6px',
                        marginLeft: '32px',
                        border: isCMBoxFocused ? '1px solid #4A3DFD' : 'none',
                        color: 'var(--text-color, black)',
                        '& .MuiSelect-select': {
                            paddingLeft: '16px',
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                            color: 'var(--text-color, black)',
                            transition: 'background-color 0.5s ease-in-out',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        },
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        },
                        '& .MuiSvgIcon-root': {
                            transition: 'color 0.5s ease-in-out',
                            color: 'var(--text-color, black)',
                        },
                    }}
                >
                    {groups.filter(group => group.id !== "00000000-0000-0000-0000-000000000000" && (group.companies?.length > 0 || group.okpds?.length > 0)).map(group => (
                        <MenuItem key={group.id} value={group.id}>
                            {group.label}
                        </MenuItem>
                    ))}
                    {okpdListData.map(item => (
                        <MenuItem key={item.okpd_code} value={item.okpd_code}>
                            {`${item.okpd_code} ${item.okpd_name}`}
                        </MenuItem>
                    ))}
                </Select>*/}
            </CommonHeader>
            <HeadDashContent/>
            <SelectedSegments/>
            <div className={styles.contentContainer}>
                <Outlet/>
            </div>
        </div>
    )
}

export default DashBoard
