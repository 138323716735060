import styles from './Layout.module.css';
import SliderMenu from "../SliderMenu/SliderMenu";
import {useModal} from "../../hook/useModal";
import Modal from "../Modal/ModalComponent/Modal";
import DashBoard from "./DashBoard/DashBoard";
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const Layout = () => {
    const { isModalVisible, modalContent, setIsModalVisible } = useModal();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/content/dashBoard') {
            navigate('/dashboard');
        }
    }, [location.pathname, navigate]);

    const renderMainContent = () => {
        switch (location.pathname) {
          /*  case '/personal':
                return <MasterPersonal />;*/
            case '/dashboard':
                return <DashBoard />;
          /*  case '/content/search':
                return <TestSearch />;
            case '/content/ratings':
                return <TestRatings />;*/
            default:
                return;
        }
    };

    return (
        <div className={styles.appContainer}>
            <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
                {modalContent}
            </Modal>
            <div>
                <SliderMenu/>
            </div>
            {renderMainContent()}
        </div>
    );
};

export default Layout;
