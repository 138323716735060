import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {
    fetchBubbleData,
    increaseLimit,
    selectCurrentLimit,
    selectLimPerPage
} from "../../../service/reducers/BubbleChartSlice";
import Legend from "../../../components/DiagrammLegend/Legend";
import {bubbleColors} from "../../../utils/colors";
import styleTooltip from "../TestMapD/GeoChart.module.css";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import styles from "../TestMapD/GeoChart.module.css";
import tooltipNames from "../../../utils/tooltipTitles.json";
import {addBubbleChartName, toggleSegment} from "../../../service/reducers/BubbleSelectedSegmentSlice";
import {formatCurrency} from "../../../utils/rubbleFunc";

const BubbleDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const { width, height } = useResizeObserver({ ref });
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { BubbleData, loading } = useSelector((state) => state.scatter);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);

    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const top = useSelector((state) => state.activitySlice);
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const clickedNodes = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const headerWithTwoButtons = {
        title: 'Распределение стоимостей единиц продукции',
        icons: [
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    const currentLimitBubble = useSelector(selectCurrentLimit);
    const limPerPage = useSelector(selectLimPerPage);
    const shouldDisplayBubble = currentLimitBubble < limPerPage;

    const handleLoadMoreBubble = () => {
        dispatch(increaseLimit());
    };

    useEffect(() => {
        const requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            trimCode,
            selectedSegments,
            filterOkpd,
            selectedMonth,
            activeTab,
            selectedOkpd,
            contractTrimCode,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            currentLimitBubble,
            selectedCountryLine,
            selectedZoomableSegment,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };

        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }

        dispatch(fetchBubbleData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedZoomableSegment,filterOkpd,dateChanger,selectedProduct, activeRegions, pieState, top, trimCode, selectedSegments, selectedMonth, relatedINNs, selectedOkpd, contractTrimCode, selectedDonutSegmetsV1, selectedContractMonth, currentLimitBubble, selectedCountryLine, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);


    useEffect(() => {
        if (loading === 'successful' && width && height && BubbleData && BubbleData.nodes) {
            createBubbles(BubbleData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, BubbleData, clickedNodes]);

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const productQuantity = d.extra.find(e => e.label === "ProductQuantity");
        const productName = d.extra.find(e => e.label === "ProductName");
        const tooltipConfig = tooltipNames.Bubble.Tabs[activeTab];

        const tooltipText = [
            { label: tooltipConfig.name, value: productName ? productName.value : 'N/A' },
            { label: tooltipConfig.price, value: formatCurrency(d.value) },
            { label: tooltipConfig.count, value: productQuantity ? productQuantity.value : 'N/A' },
            { label: tooltipConfig.country, value: d.label }
        ];

        const tooltipSize = calculateTooltipSize(tooltipText);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: tooltipText
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const createBubbles = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const margin = { top: 30, right: 30, bottom: 50, left: 55 };
        const effectiveWidth = width - margin.left - margin.right;
        const effectiveHeight = height - margin.top - margin.bottom;

        const maxY = d3.max(data.nodes, d => {
            const productQuantity = d.extra.find(e => e.label === "ProductQuantity");
            return productQuantity ? productQuantity.value : 0;
        });

        const maxX = d3.max(data.nodes, d => d.value);

        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const xScale = d3.scaleLinear()
            .domain([0, maxX])
            .range([0, effectiveWidth]);

        const yScale = d3.scaleLinear()
            .domain([0, maxY])
            .range([effectiveHeight, 0]);

        const fixedRadius = Math.min(width, height) * 0.019;

        const midValue1 = maxY / 2;
        const midValue2 = maxY / 1.35;
        const midValue3 = maxY / 4;
        const yTickValues = [midValue1, midValue2, maxY, midValue3];

        const xAxis = d3.axisBottom(xScale).ticks(5).tickSize(0);
        const yAxis = d3.axisLeft(yScale).tickValues(yTickValues).tickSize(0);

        const xAxisGroup = svg.append("g")
            .attr("transform", `translate(0, ${effectiveHeight})`)
            .call(xAxis);
        xAxisGroup.selectAll("text").attr("fill", "#8D96B2").attr("transform", "translate(0, 6)");
        xAxisGroup.selectAll(".domain").attr("stroke", "#EDF1F5");

        const yAxisGroup = svg.append("g").call(yAxis);
        yAxisGroup.selectAll("text").attr("fill", "#8D96B2").attr("transform", "translate(-6, 0)");
        yAxisGroup.selectAll(".domain").attr("stroke", "#EDF1F5");

        const colorMap = uniqueNodes.reduce((acc, node, index) => {
            acc[node.label] = bubbleColors[index % bubbleColors.length];
            return acc;
        }, {});
        // eslint-disable-next-line
        const bubbles = svg.selectAll(".bubble")
            .data(data.nodes)
            .enter().append("circle")
            .attr("class", "bubble")
            .attr("cx", d => xScale(d.value))
            .attr("cy", d => {
                const productQuantity = d.extra.find(e => e.label === "ProductQuantity");
                return yScale(productQuantity ? productQuantity.value : 0);
            })
            .attr("r", 0)
            .style("opacity", d => {
                const regNum = d.extra.find(e => e.label === "ProcedureRegNum")?.value;
                if (regNumArray.length === 0) {
                    return 1;
                } else {
                    return regNumArray.includes(regNum) ? 1 : 0.3;
                }
            })
            .attr("stroke", d => {
                const regNum = d.extra.find(e => e.label === "ProcedureRegNum")?.value;
                if (regNumArray.length === 0) {
                    return "var(--text-color)";
                } else {
                    return regNumArray.includes(regNum) ? "var(--text-color)" : "none";
                }
            })
            .attr("fill", d => colorMap[d.label])
            .on("click", (event, d) => {
                onMouseOut();
                const regNum = d.extra.find(e => e.label === "ProcedureRegNum")?.value;
                const productName = d.extra.find(e => e.label === "ProductName")?.value;
                dispatch(toggleSegment({ regNum, productName }));
                dispatch(addBubbleChartName('Распределение стоимостей единиц продукции'))
                event.stopPropagation();
            })
            .on("mousemove", (event, d) => onMouseMove(event, d))
            .on("mouseout", onMouseOut)
            .transition()
            .duration(800)
            .attr("r", fixedRadius);
    };

    const uniqueNodes = BubbleData && BubbleData.nodes ? BubbleData.nodes.reduce((acc, node) => {
        const existingNode = acc.find(existingNode => existingNode.label === node.label);
        if (existingNode) {
            existingNode.count += 1;
        } else {
            acc.push({ ...node, count: 1 });
        }
        return acc;
    }, []).sort((a, b) => b.count - a.count) : [];

    return (
        <div className={`${style.container} ${clickedNodes.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithTwoButtons}
                    onZoomClick={onZoomClick}
                    hasMoreBubble={shouldDisplayBubble}
                    loadMoreBubble={handleLoadMoreBubble}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={"Распределение стоимостей единиц продукции"} data={uniqueNodes} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={bubbleColors} />
                    </div>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styleTooltip.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}:</strong>{item.value}
                                </div>
                            ))}
                        </div>
                    )}
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                </>
            )}
        </div>
    );
};

export default BubbleDiagramm;
