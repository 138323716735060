import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import agataIconSmall from "../../common/icoSmall.svg"
import arrowLeft from "../../common/icons/collapseLeft.svg"
import arrowRight from "../../common/icons/collapseRight.svg"
import arrowLeftDark from "../../common/icons/lightLeftArrow.svg"
import arrowRightDark from "../../common/icons/lightRightArrow.svg"
import { menuItemsTop as originalMenuItemsTop } from "./menuItems";
import styles from './SliderMenu.module.css';
import {setLoadingMenu, toggleMenu} from "../../service/reducers/MenuSlice";
import Switcher from "../Switcher/Switcher";
import SwitcherCircle from "../Switcher/SwitcherCircle/SwitcherCircle";
import { useLocation } from 'react-router-dom';
import {clearParams} from "../../service/reducers/FilterSearchSlice";
import {setZoomedD} from "../../service/reducers/ZommedDSlice";

const SliderMenu = (props) => {
    const isMenuOpen = useSelector(state => state.menu.isMenuOpen);
    const [activeItem, setActiveItem] = useState(0);
    const dispatch = useDispatch();
    const switcher = useSelector(state => state.switcher.activeSwitch);
    const [showSwitcher, setShowSwitcher] = useState(true);
    const [showSwitcherCircle, setShowSwitcherCircle] = useState(false);
    const [switcherWidth, setSwitcherWidth] = useState('40px');
    const hasGraphsViewPrivilege = true;

    const location = useLocation();
    const menuItemsTop = hasGraphsViewPrivilege
        ? originalMenuItemsTop
        : originalMenuItemsTop.filter(item => item.text !== 'Дашборд');

    useEffect(() => {
        const currentPath = location.pathname;
        const activeIndex = menuItemsTop.findIndex(item => item.path === currentPath);
        setActiveItem(activeIndex !== -1 ? activeIndex : null);
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        document.documentElement.style.setProperty('--arrow-left', `url(${switcher === 'sun' ? arrowLeft : arrowLeftDark})`);
        document.documentElement.style.setProperty('--arrow-right', `url(${switcher === 'sun' ? arrowRight : arrowRightDark})`);
        if (switcher === 'sun') {
            document.documentElement.style.setProperty('--container-bg-color', '#FFF');
            document.documentElement.style.setProperty('--headerCmg-bg-color', '#F7FAFC');
            document.documentElement.style.setProperty('--scroll-bg-color', '#A2C4FF');
            document.documentElement.style.setProperty('--sliderBtn-bg-color', '#FFF');
            document.documentElement.style.setProperty('--main-container-bg-color', '#F7FAFC');
            document.documentElement.style.setProperty('--border-color', '#F7FAFC');
            document.documentElement.style.setProperty('--text-color', 'black');
            document.documentElement.style.setProperty('--switcher-item-active-bg-color', 'white');
            document.documentElement.style.setProperty('--switcher-container-bg-color', '#F7FAFC');
            document.documentElement.style.setProperty('--button-bg-color', '#F7FAFC');
            document.documentElement.style.setProperty('--modal-bg-color', '#FFF');
            document.documentElement.style.setProperty('--badge-bg-color', '#4A3DFD');
            document.documentElement.style.setProperty('--tabText-color', '#4A3DFD');
            document.documentElement.style.setProperty('--switcherBt-bg-color', '#EDF1F5');
            document.documentElement.style.setProperty('--switcherBt-text-color', '#8D96B2');
        } else if (switcher === 'moon') {
            document.documentElement.style.setProperty('--container-bg-color', '#1F283E');
            document.documentElement.style.setProperty('--headerCmg-bg-color', '#454B63');
            document.documentElement.style.setProperty('--scroll-bg-color', '#62687F');
            document.documentElement.style.setProperty('--sliderBtn-bg-color', '#343B4C');
            document.documentElement.style.setProperty('--main-container-bg-color', '#1B2134');
            document.documentElement.style.setProperty('--border-color', '#343B4C');
            document.documentElement.style.setProperty('--text-color', 'white');
            document.documentElement.style.setProperty('--switcher-item-active-bg-color', '#30394E');
            document.documentElement.style.setProperty('--switcher-container-bg-color', '#1B2134');
            document.documentElement.style.setProperty('--button-bg-color', '#30394E');
            document.documentElement.style.setProperty('--modal-bg-color', '#30394E');
            document.documentElement.style.setProperty('--badge-bg-color', '#649DFF');
            document.documentElement.style.setProperty('--tabText-color', '#FFF');
            document.documentElement.style.setProperty('--switcherBt-bg-color', '#62687F');
            document.documentElement.style.setProperty('--switcherBt-text-color', '#FFF');

        }
    }, [switcher]);

    useEffect(() => {
        if (isMenuOpen) {
            setShowSwitcherCircle(false);
            setShowSwitcher(true);
            setSwitcherWidth('210px');
        } else {
            setSwitcherWidth('0px');
            setTimeout(() => {
                setShowSwitcherCircle(true);
            }, 600);
        }
    }, [isMenuOpen]);

    const handleToggle = () => {
        dispatch(setLoadingMenu(true));
        props.toggleMenu();
        setTimeout(() => {
            dispatch(setLoadingMenu(false));
        }, 600);
    };

    const handleMenuItemClick = (index) => {
        dispatch(clearParams());
        dispatch(setZoomedD(null));
        setActiveItem(index);
    };

    const width = isMenuOpen ? '240px' : '70px';

    return (
        <div className={styles.sliderMenu} style={{ width: width }}>
            <div className={styles.sliderMenu__icon}>
                <img src={agataIconSmall} alt={'icon'}/>
                <div className={isMenuOpen ? styles.icoTextOpen : styles.icoText}>
                    <span className={styles.agatha}>Agatha</span>
                    <span className={styles.tender}>Tender</span>
                </div>
            </div>
            <div onClick={handleToggle} className={styles.sliderMenu__toggle}>
                <img
                    src={switcher === 'sun' ? arrowLeft : arrowLeftDark}
                    alt="Collapse"
                    style={{ opacity: isMenuOpen ? 1 : 0 }}
                />
                <img
                    src={switcher === 'sun' ? arrowRight : arrowRightDark}
                    alt="Expand"
                    style={{ opacity: isMenuOpen ? 0 : 1 }}
                />
            </div>
            <div className={styles.sliderMenu__items}>
                {menuItemsTop.map((item, index) => (
                    <Link to={item.path} key={index} onClick={() => handleMenuItemClick(index)} className={styles.sliderMenu__item}>
                        <img src={index === activeItem && item.darkIcon ? item.darkIcon : item.icon} alt={item.text} className={styles.sliderMenu__itemImage} />
                        <span
                            className={`${styles.sliderMenu__itemText} ${isMenuOpen ? styles.sliderMenu__itemTextOpen : ''}`}
                            style={{
                                color: index === activeItem ? 'rgba(74, 61, 253, 1)' : 'rgba(141, 150, 178, 1)',
                            }}
                                            >
                            {item.text}
                        </span>
                    </Link>
                ))}
                {showSwitcher && <Switcher width={switcherWidth} isVisible={showSwitcher}/>}
                {showSwitcherCircle && !isMenuOpen && <SwitcherCircle showSwitcherCircle={showSwitcherCircle}/>}
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    toggleMenu,
};

export default connect(null, mapDispatchToProps)(SliderMenu);
