import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import {activeColors} from "../../../utils/colors";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {
    fetchSunData,
    increaseLimit,
    selectCurrentLimit,
    selectLimPerPage, setSunKeyMode
} from "../../../service/reducers/SunKeyChartSlice";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";
import styles from "../TestMapD/GeoChart.module.css";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import {formatCurrency} from "../../../utils/rubbleFunc";
import {findOkpdNameByCode} from "../../../utils/findOKPDNameGlobal";
import styleTooltip from "../TestMapD/GeoChart.module.css";
import tooltipNames from "../../../utils/tooltipTitles.json";
import localStorageService from "../../../service/localStorage/localStorageService";
const SunDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const { width, height } = useResizeObserver({ ref });
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const { SunKeyData, loading} = useSelector((state) => state.sunKey);
    const { sunKeyMode } = useSelector((state) => state.sunKey);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);

    const {selectedSegments} = useSelector((state) => state.treeMapSlice);
    const top = useSelector((state) => state.activitySlice);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth)
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    const shouldShowChangeButton = selectedOrganization.type === 'okpd' || filterOkpd.length > 0;
    const headerWithThreeButtons = {
        title: 'Структура потоков средств через ОКПД2/НДС',
        icons: [
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            ...shouldShowChangeButton ? [{ name: 'change', icon: icons.change, width: 20, height: 20 }] : [],
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };
    const currentLimit = useSelector(selectCurrentLimit);
    const limPerPage = useSelector(selectLimPerPage);
    const shouldDisplayButton = currentLimit < limPerPage;
    const okpdData = localStorageService.getItem('okpdData') || [];

    const handleLoadMore = () => {
        dispatch(increaseLimit());
    };

    useEffect(() => {
        let requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            trimCode,
            selectedSegments,
            filterOkpd,
            selectedMonth,
            activeTab,
            selectedOkpd,
            contractTrimCode,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            currentLimit,
            selectedCountryLine,
            selectedZoomableSegment,
            regNumArray,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };

        if (shouldShowChangeButton) {
            const isCustomer = sunKeyMode === '' || sunKeyMode === 'cust';
            requestData = { ...requestData, isCustomer };
        }

        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }

        dispatch(fetchSunData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureRegNum,selectedZoomableSegment,filterOkpd,dateChanger,selectedProduct,sunKeyMode, activeRegions, pieState, top, trimCode, selectedSegments, selectedMonth, relatedINNs, selectedOkpd, contractTrimCode, selectedContractMonth, currentLimit, selectedCountryLine, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);


    useEffect(() => {
        if ( loading === 'successful' && width && height && SunKeyData) {
            createSunChart(SunKeyData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, SunKeyData]);

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const tooltipConfig = tooltipNames.Sunkey.Tabs[activeTab];
    const onMouseMove = (event, d) => {
        let tooltipData;
        const codeRegex = /^(\d+\.)+\d+$/;
        const innRegex = /^\d{10}(\d{2})?$/;
        if (codeRegex.test(d.name) || (d.name.length <= 2 && !innRegex.test(d.name))) {
            tooltipData = [
                { label: tooltipConfig.okpdCode, value: d.name },
                { label: tooltipConfig.okpdName, value: findOkpdNameByCode(d.name, okpdData) },
                { label: tooltipConfig.summary, value: formatCurrency(d.value) },
            ];
        } else {
            const targetInfo = d.targetLinks && d.targetLinks.length > 0 ? d.targetLinks[0].supFullName : '';
            const sourceInfo = d.sourceLinks && d.sourceLinks.length > 0 ? d.sourceLinks[0].supFullName : '';
            const companyName = targetInfo || sourceInfo;
            tooltipData = [
                { label: tooltipConfig.inn, value: d.name },
                { label: tooltipConfig.innName, value: companyName },
                { label: tooltipConfig.summary, value: formatCurrency(d.value) }
            ];
        }

        const tooltipSize = calculateTooltipSize(tooltipData);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: tooltipData
        });
    };

    const onMouseMoveLink = (event, d) => {
        const isINN = (code) => /^\d{10,12}$/.test(code);
        let sourceLabel, targetLabel;
        let sourceName, targetName;

        if (isINN(d.source.name.trim())) {
            sourceName = `${d.supFullName}(${d.source.name.trim()})`;
            sourceLabel = tooltipConfig.from;
        } else {
            sourceName = findOkpdNameByCode(d.source.name.trim(), okpdData);
            sourceLabel = tooltipConfig.from;
        }

        if (isINN(d.target.name.trim())) {
            targetName = `${d.supFullName}(${d.target.name.trim()})`;
            targetLabel = tooltipConfig.to;
        } else {
            targetName = findOkpdNameByCode(d.target.name.trim(), okpdData);
            targetLabel = tooltipConfig.to;
        }

        const tooltipData = {
            from: sourceName,
            to: targetName,
            value: d.value
        };

        const tooltipSize = calculateTooltipSize([tooltipData]);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: [
                { label: sourceLabel, value: tooltipData.from },
                { label: targetLabel, value: tooltipData.to },
                { label: tooltipConfig.summ, value: formatCurrency(tooltipData.value) },
            ]
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const createSunChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3
            .select(ref.current)
            .append('svg')
            .attr("viewBox", [0, -10, width, height +10])
            .attr('width', width)
            .attr('height', height);
        const nodes = new Set();
        const links = [];

        if (data.nodes && Array.isArray(data.nodes)) {
            data.nodes.forEach((node) => {
                if (node.extra && node.extra.length >= 2) {
                    nodes.add(node.extra[0].value);
                    nodes.add(node.extra[1].value);
                    const fullName = node.extra.find(e => e.label === 'CustFullName' || e.label === 'SupFullName');

                    links.push({
                        source: node.extra[0].value,
                        target: node.extra[1].value,
                        value: node.value,
                        supFullName: fullName ? fullName.value : 'Неизвестно'
                    });
                }
            });
        } else {
            console.error('data.nodes is null or not an array');
            return;
        }

        const nodesArray = Array.from(nodes);

        const sankeyGenerator = sankey()
            .nodeWidth(10)
            .nodePadding(10)
            .extent([[0, 0], [width, height -10]]);

        const sankeyData = {
            nodes: nodesArray.map(name => ({ name })),
            links: links.map(d => Object.assign({}, d))
        };

        const nodeMap = new Map();
        sankeyData.nodes.forEach((node, index) => {
            nodeMap.set(node.name, index);
        });

        sankeyData.links.forEach(link => {
            link.source = nodeMap.get(link.source);
            link.target = nodeMap.get(link.target);
        });

        sankeyGenerator(sankeyData);

        const color = (name) => {
            const index = nodesArray.indexOf(name) % activeColors.length;
            return activeColors[index];
        };

        const defs = svg.append('defs');

        sankeyData.links.forEach((link, i) => {
            const gradient = defs.append('linearGradient')
                .attr('id', `gradient${i}`)
                .attr('gradientUnits', 'userSpaceOnUse')
                .attr('x1', link.source.x1)
                .attr('x2', link.target.x0);

            gradient.append('stop')
                .attr('offset', '0%')
                .attr('stop-color', color(link.source.name));

            gradient.append('stop')
                .attr('offset', '100%')
                .attr('stop-color', color(link.target.name));
        });

        svg.append("g")
            .attr("stroke", "#000")
            .selectAll("rect")
            .data(sankeyData.nodes)
            .join("rect")
            .attr("x", d => d.x0)
            .attr("y", d => d.y0)
            .attr("height", d => d.y1 - d.y0 + 1)
            .attr("width", d => d.x1 - d.x0)
            .attr("fill", d => color(d.name))
            .on("mouseover", function(event, d) {
                link.transition().duration(200).style("opacity", 0.1);
                link.filter(l => l.source.name === d.name || l.target.name === d.name)
                    .transition().duration(200).style("opacity", 1);
            })
            .on("mousemove", (event, d) => onMouseMove(event, d))
            .on("mouseout", function() {
                link.transition().duration(200).style("opacity", 1);
                svg.select("#node-label").remove();
                onMouseOut();
            });

        const link = svg.append("g")
            .attr("fill", "none")
            .selectAll(".link")
            .data(sankeyData.links)
            .enter().append("path")
            .attr("class", "link")
            .attr("d", sankeyLinkHorizontal())
            .attr("stroke", (d, i) => `url(#gradient${i})`)
            .attr("stroke-width", d => Math.max(d.width, 1))
            .on("mouseover", function(event, d) {
                link.transition().duration(200).style("opacity", 0.1);
                d3.select(this).transition().duration(200).style("opacity", 1);
            })
            .on("mousemove", (event, d) => onMouseMoveLink(event, d))
            .on("mouseout", function() {
                link.transition().duration(200).style("opacity", 1);
                onMouseOut();
            });

        svg.append("g")
            .attr("font-family", "sans-serif")
            .attr("fill", "var(--text-color, #FFF)")
            .attr("font-size", 10)
            .selectAll("text")
            .data(sankeyData.nodes)
            .join("text")
            .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
            .attr("y", d => (d.y1 + d.y0) / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
            .text(d => d.name);

    };

    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "100vh"} : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    activeMode={sunKeyMode || 'cust'}
                    hasMoreSan={shouldDisplayButton}
                    loadMoreSan={handleLoadMore}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'customer?') {
                            dispatch(setSunKeyMode('cust'));
                        } else if (mode === 'supplier?') {
                            dispatch(setSunKeyMode('org'));
                        }
                        else {
                            dispatch(setSunKeyMode(""))
                        }
                    }}
                    diagramType="sunKey"
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styles.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}:</strong>{item.value}
                                </div>
                            ))}
                        </div>
                    )}
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                </>
            )}
        </div>
    );
};

export default SunDiagramm;
