import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {fetchManyLineData} from "../../../service/reducers/ManyLinesChart/ManyLineChartSlice";
import {formatNumber} from "../../../utils/countFunc";
import styles from "../LineBarChart/LineBarChart.module.css";
import styleTooltip from "../TestMapD/GeoChart.module.css";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import tooltipNames from "../../../utils/tooltipTitles.json"
import {formatCurrency} from "../../../utils/rubbleFunc";

const ManyLineD = ({ onZoomClick, zoomedDiagram }) => {
    const [groupType, setGroupType] = useState('month');
    const dispatch = useDispatch();
    const ref = useRef();
    const { width, height } = useResizeObserver({ ref });
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const { ManyLineData, loading } = useSelector((state) => state.manyLines);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const slidePoz = useSelector(state => state.searchSwitcher.position);

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);

    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const selectedTreeMapLabels = selectedSegments.map(segment => segment.label);
    const top = useSelector((state) => state.activitySlice);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const isBrushActive = useSelector((state) => state.manyLines.isBrushActive);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);

    const activeTab = useSelector((state) => state.tabs.activeTab);    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const headerWithThreeButtons = {
        title: "Финансовое обеспечение закупок (по годам)",
        icons: [
            { name: 'inside', icon: icons.inside, activeIcon: icons.insideActive, width: 20, height: 20 },
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 },
        ]
    };

    useEffect(() => {
        const requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            trimCode,
            selectedOkpd,
            selectedCountryLine,
            filterOkpd,
            selectedTreeMapLabels,
            selectedMonth,
            activeTab,
            selectedDonutSegmetsV1,
            selectedKbrSegments,
            selectedZoomableSegment,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };

        dispatch(fetchManyLineData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedZoomableSegment,selectedKbrSegments,filterOkpd,dateChanger,selectedProduct, activeRegions, pieState, top, trimCode, selectedSegments, selectedMonth, relatedINNs, selectedDonutSegmetsV1, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);

    useEffect(() => {
        if (loading === 'successful' && width && height && ManyLineData) {
            const groupedData = groupDataByTime(ManyLineData, groupType);
            createManyLineChart(groupedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, ManyLineData, groupType, isBrushActive]);

    const groupDataByTime = (data, groupType) => {
        let groupedData = [];
        let currentGroup = null;
        let sum = 0;
        let countItems = 0;
        let currentLabel = null;

        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const itemDate = new Date(item.month);
            const itemYear = itemDate.getFullYear();
            let groupKey;

            if (groupType === 'month') {
                groupKey = itemYear + '-' + (itemDate.getMonth() + 1).toString().padStart(2, '0');
            } else if (groupType === 'quarter') {
                const quarter = Math.floor(itemDate.getMonth() / 3) + 1;
                groupKey = `${quarter}КВ-${itemYear}`;
            } else if (groupType === 'half-year') {
                groupKey = ((itemDate.getMonth() < 6) ? 'П1-' : 'П2-') + itemYear;
            } else {
                groupKey = String(itemYear);
            }

            if (currentGroup !== groupKey) {
                if (currentGroup !== null) {
                    groupedData.push({
                        label: currentLabel,
                        month: data[i-1].month,
                        summary: sum,
                        count: countItems
                    });
                }
                currentGroup = groupKey;
                currentLabel = item.label;
                sum = 0;
                countItems = 0;
            }
            sum += item.summary;
            countItems += item.count;
        }

        if (currentGroup !== null) {
            groupedData.push({
                label: currentLabel,
                month: data[data.length - 1].month,
                summary: sum,
                count: countItems
            });
        }
        return groupedData;
    };

    const formatDateByGroupType = (dateString, groupType) => {
        const date = new Date(dateString);
        switch (groupType) {
            case 'month':
                return date.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' });
            case 'quarter':
                const quarter = Math.floor(date.getMonth() / 3) + 1;
                return `${quarter} Квартал ${date.getFullYear()} г.`;
            case 'half-year':
                const half = date.getMonth() < 6 ? '1 полугодие' : '2 полугодие';
                return `${half} ${date.getFullYear()} г.`;
            case 'year':
                return `${date.getFullYear()} г.`;
            default:
                return date.toLocaleDateString('ru-RU');
        }
    };


    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const formattedMonth = formatDateByGroupType(d.month, groupType);
        const tooltipData = [
            { label: tooltipNames.ThreeYearsLines.placement, value: formattedMonth },
            { label: tooltipNames.ThreeYearsLines.totalAmount, value: formatCurrency(d.summary) },
        ];
        const tooltipSize = calculateTooltipSize(tooltipData);
        let x = event.pageX + 10;
        let y = event.pageY + 10;
        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }
        setTooltip({
            x,
            y,
            text: tooltipData
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: [] });
    };

    const createManyLineChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const margin = { top: 30, right: 30, bottom: 100, left: 55 };
        const effectiveWidth = width - margin.left - margin.right;
        const effectiveHeight = height - margin.top - margin.bottom;

        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const currentYearData = data.filter(d => d.label === 'CurrentYear');
        const firstYearData = data.filter(d => d.label === 'FirstYear');
        const secondYearData = data.filter(d => d.label === 'SecondYear');

        const allSummary = [...currentYearData, ...firstYearData, ...secondYearData].map(d => d.summary);

        const yScale = d3.scaleLinear()
            .domain([0, Math.max(...allSummary)])
            .range([effectiveHeight, 0]);

        const allMonths = [...new Set(data.map(d => {
            const date = new Date(d.month);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            return `${year}-${month}`;
        }))];


        const xScale = d3.scalePoint()
            .domain(allMonths)
            .range([0, effectiveWidth]);

        const formatDateForScale = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            return `${year}-${month}`;
        };

        const tickInterval = Math.max(...allSummary) / 4;
        const customTicks = Array.from({length: 4}, (_, i) => (i + 1) * tickInterval);

        const setTickText = (d, groupType) => {
            const date = new Date(d);
            switch (groupType) {
                case 'month':
                    return date.toLocaleString('ru', { month: 'short' }).replace('.', '');
                case 'quarter':
                    const quarter = Math.floor(date.getMonth() / 3) + 1;
                    return `КВ${quarter}`;
                case 'half-year':
                    return date.getMonth() < 6 ? '1П' : '2П';
                case 'year':
                    return date.getFullYear();
                default:
                    return '';
            }
        };

        let lastYear = null;
        const xAxis = g => g
            .attr("transform", `translate(0,${height - margin.bottom - 30})`)
            .call(d3.axisBottom(xScale).tickSizeOuter(0))
            .call(g => g.select(".domain").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick line").style("stroke", "#EDF1F5"))
            .selectAll(".tick")
            .each(function(d, i) {
                d3.select(this).select("text").remove();
                const correspondingData = data.find(item => {
                    const itemDate = new Date(item.month);
                    const tickDate = new Date(d);
                    return itemDate.getFullYear() === tickDate.getFullYear() && itemDate.getMonth() === tickDate.getMonth();
                });
                const date = new Date(correspondingData.month);
                const currentYear = date.getFullYear();
                if (correspondingData) {
                    const tickText = setTickText(correspondingData.month, groupType);
                    d3.select(this).append("text")
                        .attr("dx", "-2em")
                        .attr("dy", "0.3em")
                        .style("text-anchor", "middle")
                        .attr("transform", "rotate(-90)")
                        .style("fill", "#8D96B2")
                        .style("font-family", "Golos Regular")
                        .text(tickText);
                }
                if (lastYear !== currentYear) {
                    const yearText = d3.select(this).append("text")
                        .attr("dx", "0.5em")
                        .text(currentYear)
                        .style("fill", "#8D96B2")
                        .style("font-family", "Golos Regular")
                        .style("font-size", `${Math.min(12, width / 30)}px`);
                    const yearTextSize = yearText.node().getBBox();
                    yearText.attr("dy", `${yearTextSize.height + 30}`);
                    lastYear = currentYear;
                }
            })

        const yAxis = d3.axisLeft(yScale).tickValues(customTicks).tickSize(0).tickFormat((d) => formatNumber(d));

        svg.append("g")
            .call(xAxis);

        const yAxisGroup = svg.append("g").call(yAxis);
        yAxisGroup.selectAll("text").attr("fill", "#8D96B2").attr("transform", "translate(-6, 0)");
        yAxisGroup.selectAll(".domain").attr("stroke", "#EDF1F5");

        const line = d3.line()
            .curve(d3.curveMonotoneX)
            .x(d => xScale(formatDateForScale(new Date(d.month))))
            .y(d => yScale(d.summary));
        const drawLine = (data, color) => {
            svg.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", "transparent")
                .attr("stroke-width", 10)
                .attr("d", line)
                .on("mousemove", function(event) {
                    const [x] = d3.pointer(event, this);
                    const domain = xScale.domain();
                    const range = xScale.range();
                    const rangePoints = d3.range(range[0], range[1], xScale.step());
                    const index = d3.bisect(rangePoints, x) - 1;
                    const dataIndex = Math.max(0, Math.min(domain.length - 1, index));
                    const nearestDataPoint = data[dataIndex];

                    if (nearestDataPoint) {
                        onMouseMove(event, nearestDataPoint);
                    }
                })
                .on("mouseout", onMouseOut)

            svg.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", color)
                .attr("stroke-width", 2)
                .attr("d", line)
                .attr("stroke-dasharray", function() {
                    const length = this.getTotalLength();
                    return `${length} ${length}`;
                })
                .attr("stroke-dashoffset", function() {
                    return this.getTotalLength();
                })
                .style("pointer-events", "none")
                .transition()
                .duration(1200)
                .attr("stroke-dashoffset", 0)
        };

        const area = d3.area()
            .x(d => xScale(formatDateForScale(new Date(d.month))))
            .y0(effectiveHeight)
            .curve(d3.curveMonotoneX)
            .y1(d => yScale(d.summary));
        const drawArea = (data, color) => {
            svg.append("path")
                .datum(data)
                .attr("fill", color)
                .attr("opacity", 0.3)
                .attr("d", area)
                .attr("fill-opacity", 0)
                .style("pointer-events", "none")
                .transition()
                .duration(750)
                .attr("fill-opacity", 0.8)
        };

        drawLine(currentYearData, "#4B72FB");
        drawLine(firstYearData, "#AE40C9");
        drawLine(secondYearData, "#FEB9BA");

        drawArea(currentYearData, "#4B72FB");
        drawArea(firstYearData, "#AE40C9");
        drawArea(secondYearData, "#FEB9BA");

        const handleBrushEnd = (event) => {
            const selection = event.selection;
            if (!selection) {
                return;
            }
            const [x0, x1] = selection.map(d => {
                const dates = xScale.domain();
                const range = xScale.range();
                const rangePoints = d3.range(range[0], range[1], xScale.step());
                const i = d3.bisect(rangePoints, d);
                return new Date(dates[i - 1]);
            });

            const filteredData = data.filter(d => {
                const date = new Date(d.month);
                return date >= x0 && date <= x1;
            });

            createManyLineChart(filteredData);
        };

        let brush;

        const handleRightClick = (event) => {
            event.preventDefault();
            if (brush) {
                d3.select(".brush").call(brush.move, null);
            }
            const initialData = groupDataByTime(ManyLineData, groupType);
            createManyLineChart(initialData);
        };

        if (isBrushActive) {
            brush = d3.brushX()
                .extent([[0, 0], [effectiveWidth, effectiveHeight]])
                .on("end", handleBrushEnd);

            svg.append("g")
                .attr("class", "brush")
                .call(brush)
                .on("contextmenu", handleRightClick);
        }
    };


    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "700px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    diagramId={"Brush"}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                    <div className={styles.buttonContainter}>
                        <div
                            className={`${styles.button} ${groupType === 'month' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupType('month')}
                        >
                            Месяц
                        </div>
                        <div
                            className={`${styles.button} ${groupType === 'quarter' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupType('quarter')}
                        >
                            Квартал
                        </div>
                        <div
                            className={`${styles.button} ${groupType === 'half-year' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupType('half-year')}
                        >
                            Погулодие
                        </div>
                        <div
                            className={`${styles.button} ${groupType === 'year' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupType('year')}
                        >
                            Год
                        </div>
                    </div>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styleTooltip.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}: </strong>{formatNumber(item.value)}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ManyLineD;
